import React from 'react'
import Layout from '../components/layout'
import HeaderSection from '../components/section-header'
import Section from '../components/section'
import Typography from '@material-ui/core/Typography'

const text = 'We couldn\'t find the page you were looking for :('

export default () => (
  <Layout>
    <HeaderSection title="Page not found" />
    <Section>
      <Typography variant="h4">{text}</Typography>
    </Section>
  </Layout>
)